import $ from 'jquery';

$(() => {
  const controllerName = window.document.body.dataset.ctrl;

  if (controllerName !== 'WebhooksLogpay') {
    return;
  }

  const logpayCompleteFunction = function () {
    if (window.webkit && window.webkit.messageHandlers) {
      // Message Handler for iOS
      window.webkit.messageHandlers.logpayComplete.postMessage({ done: true });
    } else if (window.LogpayCompletionJSBridge) {
      // Message Handler for Android
      window.LogpayCompletionJSBridge.logpayComplete();
    } else if (window.opener) {
      // Message Handler for Web (e.g. Schalter). We do not pass any
      // confidential data, so source '*' is fine.
      window.opener.postMessage({ logpayComplete: true }, '*');
    }
  };

  const logpayCloseButton = $('.js-logpay-close-button');
  if (logpayCloseButton.length > 0) {
    logpayCloseButton.click(logpayCompleteFunction);
  } else {
    logpayCompleteFunction();
  }
});
